import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import AdminDashboard from "./containers/AdminDashboard";
import Backoffice from "./containers/Backoffice";
import PublicApi from "./containers/PublicApi";
import AdminApi from "./containers/AdminApi";
import AnalyticsApi from "./containers/AnalyticsApi";
import MarketplaceApi from "./containers/MarketplaceApi";
import BlockchainApi from "./containers/BlockchainApi";
import Api from "./containers/Api";
import v1Api from "./containers/v1Api";
import Notes from "./containers/Notes";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import NewNote from "./containers/NewNote";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Settings from "./containers/Settings";


export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/admindashboard" exact component={AdminDashboard} props={childProps} />
    <AppliedRoute path="/backoffice" exact component={Backoffice} props={childProps} />
    <AppliedRoute path="/publicapi" exact component={PublicApi} props={childProps} />
    <AppliedRoute path="/adminapi" exact component={AdminApi} props={childProps} />
    <AppliedRoute path="/analyticsapi" exact component={AnalyticsApi} props={childProps} />
    <AppliedRoute path="/marketplaceapi" exact component={MarketplaceApi} props={childProps} />
    <AppliedRoute path="/blockchainapi" exact component={BlockchainApi} props={childProps} />
    <AppliedRoute path="/api" exact component={Api} props={childProps} />
    <AppliedRoute path="/v1api" exact component={v1Api} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} />
    <AuthenticatedRoute path="/notes/new" exact component={NewNote} props={childProps} />
    <AuthenticatedRoute path="/notes/:id" exact component={Notes} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
