import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import difitek from '../images/difitek.png';
import backoffice from '../images/backofficeapp.png'

export default class PublicApi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div className="container">
        <Row>
          <Col md={6}>
          <h1>Public API</h1>
          <p>The API of choice for Fintech application development. Access all the required core functionality you need in one place.</p>
          </Col>
          <Col md={6}>
            <img alt="" src={backoffice} />
          </Col>
        </Row>
        <div className="container darkpanel">
          <Row>
            <Col md={6}>
            <h1>Built using serverless technology</h1>
            <p>Our serverless approach allows us to offer you a 99.99% uptime, and virtually infinite scalability, and low latency in most regions of the world. Expect your application to perform flawlessly regardless of scale, region, or device.</p>
            </Col>
            <Col md={6}>
              <img alt="" className="panelimg" src={backoffice} />
            </Col>
          </Row>
        </div>
        <div className="container lightpanel">
          <Row>
            <Col md={6}>
              <img alt="" className="panelimg" src={difitek} />
            </Col>
            <Col md={6}>
              <h1>Database Included</h1>
              <p>We will take care of database management for you. Our database structure includes the tables and fields you need for most Fintech applications, and can be further amended to suit your specific needs.</p>
            </Col>
          </Row>
        </div>
        <div className="container darkpanel">
          <Row>
            <Col md={6}>
            <h1>Maintenance and Versioning</h1>
            <p>We are constantly maintaining the API and adding new features. Choose to either freeze a certain API version or stay up to date with the most recent updates.</p>
            </Col>
            <Col md={6}>
              <img alt="" className="panelimg" src={difitek} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
