import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import difitek from '../images/difitek.png';
import backoffice from '../images/backofficeapp.png'

export default class MarketplaceApi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div className="container">
        <Row>
          <Col md={6}>
          <h1>Marketplace API</h1>
          <p>Access the best-in-class third party services through a single API layer using our integrated third-party API's</p>
          </Col>
          <Col md={6}>
            <img alt="" src={backoffice} />
          </Col>
        </Row>
        <div className="container darkpanel">
          <Row>
            <Col md={6}>
            <h1>Payments, KYC & AML, Credit Scoring, and much more</h1>
            <p>Our library of third party services includes most of the services you need to run a Fintech application. You may also suggest new third-party services or integrate your own using our integration kit.</p>
            </Col>
            <Col md={6}>
              <img alt="" className="panelimg" src={backoffice} />
            </Col>
          </Row>
        </div>
        <div className="container lightpanel">
          <Row>
            <Col md={6}>
              <img alt="" className="panelimg" src={difitek} />
            </Col>
            <Col md={6}>
              <h1>Switch between providers seamlessly</h1>
              <p>Since all services are accessed through the same protocol, you can easily switch between third-party providers, or use multiple providers when necessary.</p>
            </Col>
          </Row>
        </div>
        <div className="container darkpanel">
          <Row>
            <Col md={6}>
            <h1>Embedded with the database and API layer</h1>
            <p>Any actions you perform using third-party API's are automatically reflected in the native API and database. E.g. updated wallet balances after performing a payment with a payment gateway, or transaction ID's stored to transactions.</p>
            </Col>
            <Col md={6}>
              <img alt="" className="panelimg" src={difitek} />
            </Col>
          </Row>
        </div>
        <div className="container lightpanel">
          <Row>
            <Col md={6}>
              <img alt="" className="panelimg" src={difitek} />
            </Col>
            <Col md={6}>
              <h1>Simple management of environments and keys</h1>
              <p>Use the services in either test or live mode with separate API keys which are easy to store and switch out on the fly.</p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
