import React, { Component } from "react";
import { Jumbotron, Button, Col, Row, Media, Grid } from "react-bootstrap";
import difitek from '../images/difitek.png';
import "./Home.css";
import greenbanner from '../images/greenbanner.png';
import Typed from 'react-typed';
import architecture from '../images/difitek-architecture.png';
import workflow from '../images/workflow2.png';
import backoffice from '../images/backofficeapp.png';

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div className="container">
        <div className="absolutebanner">
          <img src={greenbanner} alt="bg" />
        </div>
        <Jumbotron bsStyle="custom">
          <h1>Build <Typed 
              strings={[
                'primary markets',
                'secondary markets',
                'OTC markets',
                'dutch auctions',
                'reverse auctions',
                'exchanges',
                'crowdfunding platforms',
                'custodian services',
                 'escrow services',
                'any marketplace']}
              typeSpeed={40}
              backSpeed={50}
              backDelay={2000} 
              loop >
            </Typed>
            <br/>for <Typed 
              strings={[
                'corporate debt.',
                'personal debt.',
                'private equity.',
                'public equity.',
                'tokens.',
                'cryptocurrency.',
                'options.',
                'hybrid securities.',
                'investment funds.',
                'real estate.',
                'derivatives.',
                'any security.']}
              typeSpeed={40}
              backSpeed={50}
              backDelay={2000}
              loop >
            </Typed>
          </h1>
          <p>
            The framework of choice for reliably developing, testing, and deploying your Fintech application in record time.
          </p>
        </Jumbotron>
        <div className="container darkpanel">
          <Row>
            <Col md={6}>
            <h1>APIs and Webhooks built for Fintech</h1>
            <p>Direct access to functionality for user onboarding, KYC & AML, deal origination, risk, transactions, settlement, distributions, and much more. View our API categories for more information.</p>
            </Col>
            <Col md={6}>
              <img alt="" className="panelimg-blue" src={architecture} />
            </Col>
          </Row>
        </div>
        <div className="container lightpanel">
          <Row>
            <Col md={6}>
              <img alt="" className="panelimg" src={backoffice} />
            </Col>
            <Col md={6}>
              <h1>Get started quickly with our pre-built applications</h1>
              <p>Use our hosted admin applications to get started. Out-of-the-box solution for user management, investment management, audit, and other administrative work.
              <br/><br/>Our user-facing starter application will get your front-end set up in minutes, just set up hosting and you're done.</p>
            </Col>
          </Row>
        </div>
        <div className="container darkpanel">
          <Row>
            <Col md={6}>
            <h1>API workflows for complex processes</h1>
            <p>API workflows string together APIs and custom logic to perform complex tasks in the back-end. Avoid coding front-end logic and let the backend workflow do the heavy lifting.</p>
            </Col>
            <Col md={6}>
              <img alt="" className="panelimg" src={workflow} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
