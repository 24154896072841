import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import difitek from '../images/difitek.png';
import backoffice from '../images/backofficeapp.png'

export default class AdminApi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div className="container">
        <Row>
          <Col md={6}>
          <h1>Admin API</h1>
          <p>The foundation for any Fintech backoffice application.</p>
          </Col>
          <Col md={6}>
            <img alt="" src={backoffice} />
          </Col>
        </Row>
        <div className="container darkpanel">
          <Row>
            <Col md={6}>
            <h1>The underlying API for our open source backoffice application</h1>
            <p>Use the Admin API for interacting with database objects and third-party services with full access. Built for admin applications and middleware scripts.</p>
            </Col>
            <Col md={6}>
              <img alt="" className="panelimg" src={backoffice} />
            </Col>
          </Row>
        </div>
        <div className="container lightpanel">
          <Row>
            <Col md={6}>
              <img alt="" className="panelimg" src={difitek} />
            </Col>
            <Col md={6}>
              <h1>Easy access to auditable data</h1>
              <p>Export large sets of data and metadata using admin Webhooks. Including information about users, investments, offerings, or any other related database objects.</p>
            </Col>
          </Row>
        </div>
        <div className="container darkpanel">
          <Row>
            <Col md={6}>
            <h1>Full access wherever required</h1>
            <p>Build features that allow viewing other users data, amending it, rolling back transactions, and much more.</p>
            </Col>
            <Col md={6}>
              <img alt="" className="panelimg" src={difitek} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
