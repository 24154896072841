import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import difitek from '../images/difitek.png';
import backoffice from '../images/backofficeapp.png'

export default class AnalyticsApi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div className="container">
        <Row>
          <Col md={6}>
          <h1>Analytics API</h1>
          <p>Perform simple and complex analytics on your data directly through API's</p>
          </Col>
          <Col md={6}>
            <img alt="" src={backoffice} />
          </Col>
        </Row>
        <div className="container darkpanel">
          <Row>
            <Col md={6}>
            <h1>Network Statistics</h1>
            <p>Access pre-made insights from your Fintech platform, including data about users, investments, offerings, and more. Data is accessible via API allowing you to trigger actions based on outcomes.</p>
            </Col>
            <Col md={6}>
              <img alt="" className="panelimg" src={backoffice} />
            </Col>
          </Row>
        </div>
        <div className="container lightpanel">
          <Row>
            <Col md={6}>
              <img alt="" className="panelimg" src={difitek} />
            </Col>
            <Col md={6}>
              <h1>Metrics for debt financing</h1>
              <p>Direct access to all the basic metrics and methods you need for a debt financing application. Includes interest rate calculation, annuities, accrued interest, and risk metrics.</p>
            </Col>
          </Row>
        </div>
        <div className="container darkpanel">
          <Row>
            <Col md={6}>
            <h1>Accounting metrics and KPIs</h1>
            <p>Retrieve necessary metrics for user dashboards, pitches, deal rooms, or complex valuations or risk calculations.</p>
            </Col>
            <Col md={6}>
              <img alt="" className="panelimg" src={difitek} />
            </Col>
          </Row>
        </div>
        <div className="container lightpanel">
          <Row>
            <Col md={6}>
              <img alt="" className="panelimg" src={difitek} />
            </Col>
            <Col md={6}>
              <h1>Risk monitoring and suspicious activity</h1>
              <p>Use our analytics tools to identify potentially fraudulent activity, suspicious logins, and multiple other threats.</p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
