import React, { Component, Fragment } from "react";
import { Col, Row, Panel, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as contentful from 'contentful';
import "./Api.css";
import _ from 'lodash';
import difitek from '../images/difitek.png';
import Code from "react-code-prettify";



export default class v1Api extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      posts: [],
      categories: [],
    };
  }

  componentDidMount() {

    const client = contentful.createClient({
      space: '4iqhgwz33nsb',
      accessToken: 'a896d89bb58d34e3cf5c292eb3b2cff7873b1cf0b876c5909100ed218c7e3699'
    });

    client.getEntries({'content_type': 'apiFunction', limit: 999}).then(response => {
      this.setState({
        posts: response.items, categories: _.uniqBy(response.items, 'fields.endpoint')
      }, () => {
        console.log(this.state.categories);
      }
    )});

    this.setState({ isLoading: false });

    if (!this.props.isAuthenticated) {
      return;
    }
  }

  render() {
    return (
      <Row className="docsbox">
        <Col md={2} className="leftmenucontainer">
        <div className="leftmenu">
          <Navbar.Brand>
            <Link to="/"><img className="logo" src={difitek} alt=""></img></Link>
          </Navbar.Brand>
          <br/>
          <br/>
        {this.state.categories.map(( d ) => (
          <Fragment>
          <h3>{d.fields.endpoint}</h3>

            {this.state.posts.map(({ fields }) =>{

              if(fields.endpoint === d.fields.endpoint){
                return <a href={"#" + fields.title}>{fields.title}<br/><br/></a>;
              }
            })}
          </Fragment>
        ))}
        </div>

        </Col>
        <Col md={10}>
          {this.state.posts.map(({ fields }) =>
            <Row>
              <Col md={6}>
                <h2 id={fields.title}>{fields.title}</h2>
                <p>{fields.body}</p>
                <Panel>
                  <Panel.Heading>Webhook URL</Panel.Heading>
                  <Panel.Body>{fields.webhook}</Panel.Body>
                </Panel>
              </Col>
              <Col className="darkcol" md={6}>
                <h3>{fields.method} {fields.route}</h3>
                <p>Request Body</p>
                <Code codeString={fields.requestBody}></Code>
                <p>Response</p>
                <Code codeString={fields.response}></Code>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  }
}
