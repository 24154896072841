import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import difitek from '../images/difitek.png';
import backoffice from '../images/backofficeapp.png'

export default class BlockchainApi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div className="container">
        <Row>
          <Col md={6}>
          <h1>Blockchain API</h1>
          <p>Interact with the Ethereum blockchain through a simple REST API.</p>
          </Col>
          <Col md={6}>
            <img alt="" src={backoffice} />
          </Col>
        </Row>
        <div className="container darkpanel">
          <Row>
            <Col md={6}>
            <h1>API-first blockchain explorer</h1>
            <p>Retrieve blocks, transactions, wallets, contracts, through a simple to use logical API. Can be used to display data, perform system logic, or trigger various events in your application.</p>
            </Col>
            <Col md={6}>
              <img alt="" className="panelimg" src={backoffice} />
            </Col>
          </Row>
        </div>
        <div className="container lightpanel">
          <Row>
            <Col md={6}>
              <img alt="" className="panelimg" src={difitek} />
            </Col>
            <Col md={6}>
              <h1>Node-less approach to blockchain</h1>
              <p>Gain access to all the features of blockchain without having to install, maintain and manage you own node.</p>
            </Col>
          </Row>
        </div>
        <div className="container darkpanel">
          <Row>
            <Col md={6}>
            <h1>Endpoints for mainnet and popular testnets</h1>
            <p>Access ropsten and rinkeby testnets in your Sandbox implementation, and the mainnet for your live environment.</p>
            </Col>
            <Col md={6}>
              <img alt="" className="panelimg" src={difitek} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
