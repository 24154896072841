import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import difitek from '../images/difitek.png';
import backoffice from '../images/backofficeapp.png';
import apiusage from '../images/apiusage.png';
import thirdparty from '../images/thirdparty.png';
import admindashboard from '../images/admindashboard.png';
import apikeys from '../images/apikeys.png';
import adminmanagement from '../images/adminmanagement.png';

export default class AdminDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div className="container">
        <Row>
          <Col md={6}>
          <h1>Admin Dashboard</h1>
          <p>Central hub for managing your Fintech application. Set up API keys, admin users, permissions, billing, and much more.</p>
          </Col>
          <Col md={6}>
            <img alt="" src={admindashboard} width={800} />
          </Col>
        </Row>
        <div className="container darkpanel">
          <Row>
            <Col md={6}>
            <h1>API Key Management</h1>
            <p>Easily manage API keys for your fintech application(s). Issue, delete, retrieve, or modify your keys easily through the straightforward interface.</p>
            </Col>
            <Col md={6}>
              <img alt="" className="panelimg" src={apikeys} />
            </Col>
          </Row>
        </div>
        <div className="container lightpanel">
          <Row>
            <Col md={6}>
              <img alt="" className="panelimg" src={apiusage} />
            </Col>
            <Col md={6}>
              <h1>Activity Monitoring</h1>
              <p>Monitor platform activity through the activity monitor. Gain valuable insights concerning usage peaks, speed, scalability, and user behaviour.</p>
            </Col>
          </Row>
        </div>
        <div className="container darkpanel">
          <Row>
            <Col md={6}>
            <h1>Administrator Management</h1>
            <p>Manage your admin users through the admin dashboard. You'll have the ability to create, delete, and block admin users, as well as manage their access rights.</p>
            </Col>
            <Col md={6}>
              <img alt="" className="panelimg" src={adminmanagement} height={200} />
            </Col>
          </Row>
        </div>
        <div className="container lightpanel">
          <Row>
            <Col md={6}>
              <img alt="" className="panelimg" src={thirdparty} />
            </Col>
            <Col md={6}>
              <h1>3rd Party Services</h1>
              <p>Browse through our library of third party services for onboarding, transactions, risk, and much more. You can simply enable and disable services through the admin dashboard.</p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
