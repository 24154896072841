import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem, NavDropdown, Row, Button, Col, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";
import difitek from './images/difitek.png';
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  async componentDidMount() {
    try {
      if ( localStorage.getItem('loginstate') === "true" ) {
        this.userHasAuthenticated(true);
        this.setState({ isAuthenticated: true }); } else {
          this.userHasAuthenticated(false);
          this.setState({ isAuthenticated: false });
      }

    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    localStorage.setItem('loginstate', false);
    this.userHasAuthenticated(false);
    this.setState({ isAuthenticated: false });
    this.props.history.push("/login");
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };
  
    return (
      !this.state.isAuthenticating &&
      <div className="App container-fluid">
        <Navbar fluid collapseOnSelect={true}>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/"><img className="logo" src={difitek} alt="" /></Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              <NavDropdown title="Components" id="basic-nav-dropdown">
                <Row className="threecol">
                  <Col md={4}>
                    <h3>Admin Tools</h3>
                    <MenuItem divider />
                    <LinkContainer to="/admindashboard">
                      <NavItem>Admin Dashboard</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/backoffice">
                      <NavItem>Backoffice</NavItem>
                    </LinkContainer>
                  </Col>
                  {/*
                  <Col md={4}>
                    <h3>APIs</h3>
                    <MenuItem divider />
                    <LinkContainer to="/publicapi">
                      <NavItem>Public API</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/adminapi">
                      <NavItem>Admin API</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/marketplaceapi">
                      <NavItem>Marketplace API</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/analyticsapi">
                      <NavItem>Analytics API</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/blockchainapi">
                      <NavItem>Blockchain API</NavItem>
                    </LinkContainer>
                  </Col>
                  <Col md={4}>
                    <h3>Workflows</h3>
                    <MenuItem divider />
                    <LinkContainer to="/admindashboard">
                      <NavItem>Admin Dashboard</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/backoffice">
                      <NavItem>Backoffice</NavItem>
                    </LinkContainer>
                  </Col>
                  */}
                </Row>
              </NavDropdown>
              <NavDropdown title="Resources" id="basic-nav-dropdown">
                <Row className="twocol">
                  <Col md={6}>
                    <h3>Getting Started</h3>
                    <MenuItem divider />
                    <Link to="https://github.com/crowdvalley" target="new">
                      <NavItem href="https://github.com/crowdvalley">SDK's</NavItem>
                    </Link>
                    <Link to="hhttps://github.com/crowdvalley/v4realestatebackoffice" target="new">
                      <NavItem href="https://github.com/crowdvalley/v4realestatebackoffice">Real Estate Backoffice</NavItem>
                    </Link>
                  </Col>
                  <Col md={6}>
                    <h3>Tutorials</h3>
                    <MenuItem divider />
                  </Col>
                </Row>
              </NavDropdown>
            </Nav>
            <Nav pullRight>
              <LinkContainer to="/api">
                  <NavItem className="b-documentation_button"><Button bsStyle="blue">Documentation</Button></NavItem>
              </LinkContainer>
              {this.state.isAuthenticated
                ? <Fragment>
                    <NavItem onClick={this.handleLogout}>Logout</NavItem>
                  </Fragment>
                : <Fragment>
                    {/*<LinkContainer to="/login">
                      <NavItem>Login</NavItem>
              </LinkContainer>*/}
                    <Link to="https://dashboard.sandbox.difitek.com" target="new">
                <Button bsStyle="blue" href="https://dashboard.sandbox.difitek.com">FREE Sandbox</Button>
              </Link>
                  </Fragment>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <Routes childProps={childProps} />

        <Row className="dark" bsClass="centered">
          <h3>Ready to start building your Fintech application?</h3>
          <Button bsStyle="blue">SIGN UP</Button>
        </Row>
        <Row className="footer">
          <div className="container">
            <Row>
              <Col md={12}>
                <img className="logo" src={difitek} alt="logo"></img>
              </Col>
              {/*
              <Col md={3}>
                <h5>Features</h5>
                <ul className="list-unstyled text-small">
                  <li><a className="text-muted" href="#">Cool stuff</a></li>
                  <li><a className="text-muted" href="#">Random feature</a></li>
                  <li><a className="text-muted" href="#">Team feature</a></li>
                  <li><a className="text-muted" href="#">Stuff for developers</a></li>
                  <li><a className="text-muted" href="#">Another one</a></li>
                  <li><a className="text-muted" href="#">Last time</a></li>
		            </ul>
              </Col>
              <Col md={3}>
                <h5>Features</h5>
                <ul className="list-unstyled text-small">
                  <li><a className="text-muted" href="#">Cool stuff</a></li>
                  <li><a className="text-muted" href="#">Random feature</a></li>
                  <li><a className="text-muted" href="#">Team feature</a></li>
                  <li><a className="text-muted" href="#">Stuff for developers</a></li>
                  <li><a className="text-muted" href="#">Another one</a></li>
                  <li><a className="text-muted" href="#">Last time</a></li>
		            </ul>
              </Col>
              <Col md={3}>
                <h5>Features</h5>
                <ul className="list-unstyled text-small">
                  <li><a className="text-muted" href="#">Cool stuff</a></li>
                  <li><a className="text-muted" href="#">Random feature</a></li>
                  <li><a className="text-muted" href="#">Team feature</a></li>
                  <li><a className="text-muted" href="#">Stuff for developers</a></li>
                  <li><a className="text-muted" href="#">Another one</a></li>
                  <li><a className="text-muted" href="#">Last time</a></li>
		            </ul>
              </Col>
              <Col md={3}>
                <h5>Features</h5>
                <ul className="list-unstyled text-small">
                  <li><a className="text-muted" href="#">Cool stuff</a></li>
                  <li><a className="text-muted" href="#">Random feature</a></li>
                  <li><a className="text-muted" href="#">Team feature</a></li>
                  <li><a className="text-muted" href="#">Stuff for developers</a></li>
                  <li><a className="text-muted" href="#">Another one</a></li>
                  <li><a className="text-muted" href="#">Last time</a></li>
		            </ul>
              </Col>
              */}
            </Row>
            
          </div>
        </Row>
      </div>
    );
  }
}

export default withRouter(App);
